@use 'node_modules/@angular/material' as mat;

@import './assets/scss/generate';
@import './assets/scss/variables';
@import './assets/scss/layout';
@import './assets/scss/focus';
@import './assets/scss/picture';
@import './assets/scss/text';
@import './assets/scss/border';
@import './assets/scss/color';
@import './assets/scss/cursor';
@import './assets/scss/print';
@import './assets/scss/legacy/legacy';
@import './assets/icon/Line-Icons/v1/style.css';
@import './assets/icon/Line-Icons/v2/style.css';
@import './assets/icon/Line-Icons/v3/style.css';
@import './assets/icon/Line-Icons/v4/style.css';
@import './assets/icon/Solid-Icons/v1b/style.css';
@import './assets/icon/Solid-Icons/v2b/style.css';
@import './assets/icon/Solid-Icons/v3b/style.css';
@import './assets/icon/Solid-Icons/v4b/style.css';
@import './assets/scss/override/quill';

@include mat.core();

$mat-light-theme-background: (background: #FFFFFF);
$typo: mat.define-typography-config(
  $font-family: 'Noto Sans KR',
  $headline-1: mat.define-typography-level(112px, 112px, 600),
  $headline-2: mat.define-typography-level(56px, 56px, 600),
  $headline-3: mat.define-typography-level(45px, 48px, 600),
  $headline-4: mat.define-typography-level(34px, 40px, 600),
    //기존 headline
  $headline-5: mat.define-typography-level(27px, 33px, 600),
    //기존 title
  $headline-6: mat.define-typography-level(24px, 30px, 600),
    //기존 subheading-2
  $subtitle-1: mat.define-typography-level(20px, 22px, 600),
    //기존 subheading-1
  $subtitle-2: mat.define-typography-level(16px, 22px, 500),
    //기존 body-2
  $body-1: mat.define-typography-level(14px, 22px, 500),
    //기존 body-1
  $body-2: mat.define-typography-level(13px, 22px, 500),
  $caption: mat.define-typography-level(12px, 22px, 500),
  $button: mat.define-typography-level(14px, 14px, 600)
);

@include mat.all-component-typographies($typo);

//$light-primary-text: #333333;
//$dark-primary-text: #FFFFFF;
//kdh.2024.0129 수정
$light-primary-text: #FFFFFF;
$dark-primary-text: #212121;


$lawcost-primary-palette: (
  50: #95A9C1,
  100: #849BB7,
  200: #728DAC,
  300: #617FA2,
  400: #4F7198,
  500: #3E638E,
  600: #395A82,
  700: #335275,
  800: #2E4968,
  900: #28405B,
  A100: #82b1ff,
  A200: $primary-light,
  A400: $primary,
  A700: $primary-darker,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$lawcost-accent-palette: (
  50: #FFE89F,
  100: #FFE48F,
  200: #FFE07F,
  300: #FFDC6F,
  400: #FFD85F,
  500: #FFD54F,
  600: #E8C248,
  700: #D1AF41,
  800: #BA9B3A,
  900: #A38833,
  A100: #82b1ff,
  A200: $primary-light,
  A400: $primary,
  A700: $primary-darker,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$lawcost-warn-palette: (
  50: #F57F84,
  100: #F46A70,
  200: #F2555C,
  300: #F14047,
  400: #EF2B33,
  500: #EE161F,
  600: #D9141D,
  700: #C3131A,
  800: #AE1117,
  900: #980F14,
  A100: #82b1ff,
  A200: $primary-light,
  A400: $primary,
  A700: $primary-darker,
  contrast: (
    50: $light-primary-text,
    100: $light-primary-text,
    200: $light-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$lawcost-primary: mat.define-palette($lawcost-primary-palette);
$lawcost-accent: mat.define-palette($lawcost-accent-palette);
$lawcost-warn: mat.define-palette($lawcost-warn-palette);
$lawcost-theme: mat.define-light-theme($lawcost-primary, $lawcost-accent, $lawcost-warn);

@include mat.all-component-themes($lawcost-theme);

@import './assets/scss/override/typography';
@import './assets/scss/override/tooltip';
@import './assets/scss/override/input';
@import './assets/scss/override/icon';
@import './assets/scss/override/button';
@import './assets/scss/override/list';
@import './assets/scss/override/card';
@import './assets/scss/override/tab';
@import './assets/scss/override/menu';
@import './assets/scss/override/table';
@import './assets/scss/override/dialog';
@import './assets/scss/override/swiper';
@import './assets/scss/override/bottom-sheet';

.gallery-img {
  width: 100%;
  height: auto;
}

.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #3e638e;
  --mdc-radio-selected-hover-icon-color: $primary;
  --mdc-radio-selected-icon-color: $primary;
  --mdc-radio-selected-pressed-icon-color: #3e638e;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: $primary!important;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: $primary;
  --mdc-checkbox-selected-focus-icon-color: $primary;
  --mdc-checkbox-selected-hover-icon-color: $primary;
  --mdc-checkbox-selected-icon-color: $primary;
  --mdc-checkbox-selected-pressed-icon-color: $primary;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: $primary;
  --mdc-checkbox-selected-hover-state-layer-color: $primary;
  --mdc-checkbox-selected-pressed-state-layer-color: $primary;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

html {
  --mdc-filled-text-field-caret-color: $primary;
  --mdc-filled-text-field-focus-active-indicator-color: $primary;
  --mdc-filled-text-field-focus-label-text-color: rgba(62, 99, 142, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-focus-label-text-color: #ee161f;
  --mdc-filled-text-field-error-label-text-color: #ee161f;
  --mdc-filled-text-field-error-caret-color: #ee161f;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #ee161f;
  --mdc-filled-text-field-error-focus-active-indicator-color: #ee161f;
  --mdc-filled-text-field-error-hover-active-indicator-color: #ee161f;
  --mdc-outlined-text-field-caret-color: $primary;
  --mdc-outlined-text-field-focus-outline-color: $primary;
  --mdc-outlined-text-field-focus-label-text-color: $primary;
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #ee161f;
  --mdc-outlined-text-field-error-focus-label-text-color: #ee161f;
  --mdc-outlined-text-field-error-label-text-color: #ee161f;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #ee161f;
  --mdc-outlined-text-field-error-hover-outline-color: #ee161f;
  --mdc-outlined-text-field-error-outline-color: #ee161f;
  --mat-form-field-focus-select-arrow-color: rgba(62, 99, 142, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #ee161f;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.12;
}


//hs add

.swal2-popup {
  padding: 20px 10px!important;
  display: none;
  position: relative;
  box-sizing: border-box;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  width: 32em;
  max-width: 100%;
  border: none;
  border-radius: 0.3125em;
  background: #fff;
  font-family: inherit;
  font-size: 1rem;
}

.swal2-title {
  position: relative;
  max-width: 100%;
  margin: 0 0 0.4em;
  padding: 0;
  font-weight: 600;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
  font-size: 20px!important;
  line-height: 1.4;
  color: #212121!important;
}

.swal2-content {
  z-index: 1;
  -webkit-box-pack: center;
  justify-content: center;
  margin: 0;
  padding: 15px!important;
  color: #545454;
  font-size: 1em!important;
  font-weight: 400;
  line-height: 32px!important;
  text-align: center;
  word-wrap: break-word;
}

.swal2-actions button {
  height: 32px;
  line-height: 13px!important;
  border-radius: 20px!important;
  font-size: 14px!important;
}

.swal2-cancel {
  background-color: #fafafa!important;
  color: #212121!important;
  min-width: 160px;
}

.swal2-confirm {
  background-color: #212121!important;
  min-width: 160px;
  color: #fff;
}

.cdk-overlay-pane .no-padding-container{
  min-width: 25vw!important;
}













.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(90deg,rgba(0,0,0,.7),rgba(0,0,0,.7) 2px,transparent 0,transparent);
}

.mat-slider-thumb {
  position: absolute;
  right: -10px;
  bottom: -10px;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  border: 3px solid transparent;
  border-radius: 50%;
  transform: scale(0.7);
  transition: transform 400ms cubic-bezier(0.25, 0.8, 0.25, 1),background-color 400ms cubic-bezier(0.25, 0.8, 0.25, 1),border-color 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
}

.mat-mdc-slider .mdc-slider__thumb-knob {
  border-radius: var(--mdc-slider-handle-shape);
  width: 10px!important;
  height: 10px!important;
  border-style: solid;
  border-width: calc(var(--mdc-slider-handle-height) / 2) calc(var(--mdc-slider-handle-width) / 2);
  background-color: #212121!important;
  border-color: #212121!important;
}

html {
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 10px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 10px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
}

.mat-mdc-slider .mdc-slider__track {
  height: 2px!important;
}

.mat-mdc-slider .mdc-slider__track--inactive {
  height: 2px!important;
  background-color: #212121!important;
}

.mat-mdc-slider .mdc-slider__track--active {
  height: 2px!important;
  top: calc((var(--mdc-slider-inactive-track-height) - var(--mdc-slider-active-track-height)) / 2);
}

.mat-mdc-slider .mdc-slider__track--active_fill {
  border-color: rgba(0, 0, 0, 0.26);
}











.mat-step-label.mat-step-label-selected {
  font-size: 13px!important;
  font-weight: var(--mat-stepper-header-selected-state-label-text-weight);
}




.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent!important;
}
