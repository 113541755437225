@import "../variables";

.c-help {
  color: $primary-darker;
}

.w-80 {
  width: 80%;
}
.w-90 {
  width: 90%;
}
.w-95 {
  width: 95%;
}

.w-100 {
  width: 100%;
}

.border-top-pay {
  margin-bottom: 10px;
  border-top: 1px dashed $gray;
}

.border-bottom-pay {
  margin: 5px 0;
  border-bottom: 1px dashed $gray;
}

.pay-table-01 {
  margin: 0 20px 20px 20px;
  background-color: $gray-light;
  padding: 7px 0;
  font-weight: 500;
}

.pay-table-02 {
  margin: 0 20px 20px 20px;
  background-color: $gray-light;
  padding: 7px 0;
  font-weight: 500;
}

.pay-table-03 {
  margin: 10px 0;
  border-bottom: 1px solid #E0E0E0;
  padding-bottom: 5px;
  padding-top: 5px;
  font-size: 12px;
}

.pay-table-04 {
  margin: 10px 0;
  border-bottom: 1px solid #E0E0E0;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 10px;
  font-size: 12px;
}

.pay-table-05 {
  margin: 10px 0;
  padding-bottom: 10px;
  padding-top: 5px;
  font-size: 12px;
}

div.mat-expansion-panel-body {
  padding: 0 !important;
}
