@import './variables';

:focus {
  outline: none;
}

.hover:hover > * {
  background: inherit !important;
  font-weight: 700 !important;
}

.hover-primary:hover {
  background: $primary-darker !important;
  color: #ffffff !important;
}

.hover-primary-line:hover {
  background: inherit !important;
  color: $primary-darker !important;
  border: 1px solid $primary-darker !important;
}
