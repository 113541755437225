@import './_variables.scss';

html, body {
  min-height: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  margin: 0;
  font-family: 'Noto Sans KR', sans-serif !important;
  font-size: 13px;
  line-height: 1.7;
  letter-spacing: -0.01em;
  -ms-overflow-style: none;
  overflow: auto;
}

section {
  padding: 40px 0  25px 0;
  max-width: 1000px;
  min-height: calc(100vh - 200px);
  left: 0;
  right: 0;
  margin: auto;
}

@media screen and (max-width: 959px) {
  section {
    padding: 25px 0;
    min-height: calc(100vh - 225px);
  }
}

.wide-section {
  max-width: 1150px;
}

.narrow-section {
  max-width: 1000px;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #ffffff;
  padding: 25px 20px!important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

@media screen and (max-width: 959px) {
  .narrow-section {
    max-width: 1000px;
    left: 0;
    right: 0;
    margin: auto;
    background-color: #ffffff;
    padding: 15px 0 !important;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
}

.slim-section {
  max-width: 800px;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #ffffff;
  padding: 25px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

@media screen and (max-width: 959px) {
  .slim-section {
    max-width: 800px;
    left: 0;
    right: 0;
    margin: auto;
    background-color: #ffffff;
    padding: 15px 0;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
}

.slim-section {
  max-width: 800px;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #ffffff;
  padding: 25px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
@media screen and (max-width: 959px) {
  .slim-section {
    max-width: 800px;
    left: 0;
    right: 0;
    margin: auto;
    background-color: #ffffff;
    padding: 15px 0;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
}

.wide-section {
  max-width: 1150px;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #ffffff;
  padding: 25px 25px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
@media screen and (max-width: 959px) {
  .wide-section {
    max-width: 1150px;
    left: 0;
    right: 0;
    margin: auto;
    background-color: #ffffff;
    padding: 15px 0px!important;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
}

.none-padding-section {
  max-width: 900px;
  left: 0;
  right: 0;
  margin: auto;
  padding: 0 !important;
}

.banner {
  min-height: 80px !important;
}

.banner-xs {
  min-height: 50px !important;
}

.display-block {
  display: block;
}

.m-w-170 {
  min-width: 170px;
}

.m-h-190 {
  min-height: 190px;
}

.mat-horizontal-content-container {
  overflow: hidden;
  padding: 0 5px 24px 5px!important;
}
