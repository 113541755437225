.mat-card:not([class*='mat-elevation-z']) {
  box-shadow: none !important;
}

.mat-shadow-a {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.13) !important;
}

.mat-shadow-b {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.09) !important;
}

.mat-shadow-c {
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, .28) !important;
}
.mat-shadow-d {
  box-shadow: 0 5px 17px 0 rgba(0,0,0,.1)!important;
}
.mat-shadow-e {
  box-shadow: -5px 5px 17px -5px rgba(0,0,0,.1)!important;
}

.mat-shadow-g {
  box-shadow: 0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)!important;
}
.mat-shadow-none {
  box-shadow: none !important;
}


.mat-shadow-bottom-b {
  box-shadow: 0px 5px 10px -5px rgba(32, 33, 36, .2)!important;
}

.border-white {
  border: 1px solid #ffffff !important;
}
.brd-2 {
  border-radius: 2px !important;
}
.brd-4 {
  border-radius: 4px !important;
}
.brd-6 {
  border-radius: 6px !important;
}
.brd-10 {
  border-radius: 10px !important;
}
.brd-15 {
  border-radius: 15px !important;
}
.brd-17 {
  border-radius: 17px !important;
}
.brd-20 {
  border-radius: 20px !important;
}

.mat-expansion-panel {
  background: inherit!important;
}
.min-h-150 {
  min-height: 150px;
}

.border-bottom-primary {
  border-bottom: 2px dotted $primary!important;
}

.bg-gradiant-primary {
  background: #33cccc!important;
  background: -webkit-linear-gradient(to right, #14b2ae, #33cccc)!important;
  background: linear-gradient(to right, #14b2ae, #33cccc)!important;
}
