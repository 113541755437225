.no-padding-container .mat-dialog-container {
  padding: 15px;

  .mat-horizontal-content-container {
    padding: 15px;
  }

  .mat-horizontal-stepper-header-container {
    display: none;
  }
}

.mat-dialog-container {
  overflow: auto !important;
  padding: 15px 20px!important;
}

.mat-dialog-content {
  &::-webkit-scrollbar {
    display: none !important;
  }
}

.mat-dialog-actions {
  padding: 8px 0;
  display: flex;
  flex-wrap: wrap;
  min-height: 70px !important;
  align-items: center;
  margin-bottom: -24px;
}
