.swiper-button-next, .swiper-button-prev {
  display: none !important;
}

.swiper-pagination-bullet {
  border-color: #eaeaea !important;
  background-color: #eaeaea !important;
  opacity: 1 !important;
  width: 5px!important;
  height: 5px!important;
}

.swiper-pagination-bullet-active {
  width: 5px!important;
  height: 5px!important;
  border-color: transparent !important;
  background: #000000 !important;
  opacity: 1 !important;
}
