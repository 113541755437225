//$tool-bar-row-height-gt-sm: 50px;
//$tool-bar-row-height-lt-md: 64px;
$tool-bar-row-height-gt-sm: 55px;
$tool-bar-row-height-lt-md: 64px;
// Color

$gray-light: #fafafa;
$gray: #999999;
$gray-deep: #666666;
$gray-dark: #212121;

$primary-light: #ABD8D8;
$primary: #40bfc0;
$primary-darker: #14b2ae;

$accent-light: #FFE07F;
$accent: #FFD54F;
$accent-darker: #D1AF41;

$warn-light: #f8a0a5;
$warn: #F46A70;
$warn-darker: #f1414a;
