@import '../variables';

.mat-tooltip {
  background: $gray-deep !important;
  color: #ffffff !important;
  font-size: 13px !important;
  line-height: 1.7 !important;
  max-width: 300px !important;
  font-weight: 500!important;
  bottom: 30px!important;
}
