@import '../_variables.scss';

.mat-pseudo-checkbox-checked {
  background-color: $gray-dark !important;
  //background-color: transparent !important;
}

.mat-checkbox-checked {
  background-color: $gray-dark !important;
  color: #FFFFFF;
  //background-color: transparent !important;
}

.mat-pseudo-checkbox {
  display: none!important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
  border-color: $gray-dark !important;
  background-color: $gray-dark !important;
  color: #FFFFFF;
}
