@import '../_variables.scss';

button {
  box-shadow: none !important;
}

button:not(.mat-fab):not(.mat-menu-item):not(.swal2-styled):not(.button-mini):not(.mat-mini-fab):not(.mat-icon-button) {
  border-radius: 50px;
  line-height: 33px;
}

.inline-button {
  display: inline !important;
}

.mat-button {
  min-width: 55px !important;
  padding: 6px 10px;
}

.mat-button-wide {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.button-mini {
  border-radius: 20px !important;
  font-size: 12px !important;
  line-height: 12px !important;
  padding: 8px 10px!important;
  color: #212121;
  border: 1px solid #dadada;
}

.button-mini-noborder {
  border-radius: 20px !important;
  font-size: 12px;
  line-height: 12px !important;
  padding: 6px 6px;
  color: #212121;
}

.button-mini-w {
  border-radius: 20px !important;
  font-size: 12px !important;
  line-height: 12px !important;
  padding: 6px 8px !important;
  background-color: inherit !important;
  font-weight: 500;
  color: #212121;
}

.button-category-on {
  border-radius: 20px !important;
  border: 1px solid $primary-darker !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  background-color: inherit;
  padding: 2px 8px !important;
  color: $primary-darker !important;
  margin-bottom: 5px !important;
}

.button-category-off {
  border-radius: 20px !important;
  border: 1px solid $gray !important;
  font-size: 12px !important;
  line-height: 12px !important;
  background-color: inherit;
  padding: 6px 8px !important;
  color: $gray !important;
  margin-bottom: 5px !important;
}

.button-mini-n {
  border-radius: 4px !important;
  font-size: 13px !important;
  line-height: 13px !important;
  background-color: inherit;
  padding: 6px 10px;
  padding-bottom: 5px !important;
}

.button-md {
  border-radius: 20px !important;
  font-size: 13px!important;
  padding: 8px 13px;
  line-height: 13px;
  box-shadow: none !important;
}

.button-md-white {
  border-radius: 4px !important;
  font-size: 13px!important;
  padding: 8px 13px;
  line-height: 13px;
  border: 1px solid #ffffff !important;
}

.button-lg {
  padding: 10px 100px !important;
  border-radius: 4px !important;
  font-size: 14px;
  font-weight: 600 !important;
}

.button-14 {
  font-size: 14px !important;
  font-weight: 600 !important;
  padding-left: 40px!important;
  padding-right: 40px!important;
}

.button-suffix {
  height: 32px;
  margin-top: 10px !important;
  font-size: 13px !important;
  border: 1px solid $gray !important;
  color: $gray;
  line-height: 30px !important;
}

.button-suffix-primary {
  height: 32px;
  margin-top: 10px !important;
  font-size: 13px !important;
  border: 1px solid $primary-darker !important;
  color: $primary-darker;
  line-height: 32px !important;
}

.mat-mini-fab {
  .mat-button-wrapper {
    font-size: 12px;
    font-weight: 400 !important;
    line-height: 12px !important;
  }
}

.mat-mini-fab {
  font-size: 13px;
  font-weight: 600 !important;
  line-height: 13px !important;
}

.mat-fab {
  .mat-button-wrapper {
    font-size: 13px;
    line-height: 13px !important;
    font-weight: 600;
  }
}

.mat-raised-button {
  padding: 6px 15px;
  font-size: 14px;
  line-height: 14px;
}

.mat-stroked-button {
  padding: 6px 15px;
  font-size: 14px;
  line-height: 14px;
}

.cancel-chip {
  min-width: 35px;
  padding: 6px 7px;
  border-radius: 20px;
  border: 1px solid $warn;
  color: $warn;
  font-size: 11px;
  line-height: 11px;
  font-weight: 600;
  text-align: center;
}

.target-chip {
  min-width: 35px;
  padding: 6px 8px;
  border-radius: 20px;
  border: 1px solid $primary-darker;
  color: $primary-darker;
  font-size: 11px;
  line-height: 11px;
  font-weight: 600;
  text-align: center;
}
.target-chip-bg {
  min-width: 35px;
  padding: 6px 8px;
  border-radius: 20px;
  border: 1px solid $primary-darker;
  background-color: $primary-darker;
  color: #ffffff;
  font-size: 11px;
  line-height: 11px;
  font-weight: 600;
  text-align: center;
}

.black-chip {
  min-width: 35px;
  padding: 6px 12px;
  border-radius: 20px;
  border: 1px solid #444444;
  color: #444444;
  font-size: 11px;
  line-height: 11px;
  font-weight: 600;
  text-align: center;
}
.white-chip {
  min-width: 35px;
  padding: 6px 7px;
  border-radius: 20px;
  border: 1px solid #ffffff;
  color: #ffffff;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  text-align: center;
}

.gray-chip {
  width: fit-content;
  min-width: 35px;
  padding: 6px 8px;
  border-radius: 20px;
  border: 1px solid #777777;
  color: #777777;
  font-size: 11px;
  line-height: 11px;
  font-weight: 600;
  text-align: center;
}

.primary-bg-chip {
  min-width: 45px;
  padding: 6px 10px;
  border-radius: 20px;
  background-color: $primary;
  color: #ffffff;
  font-size: 11px;
  line-height: 11px;
  font-weight: 500;
  text-align: center;
}

.send-bg-chip {
  min-width: 45px;
  padding: 6px 10px;
  border-radius: 20px;
  background-color: $primary;
  color: #ffffff;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  text-align: center;
}
.receive-bg-chip {
  min-width: 45px;
  padding: 6px 10px;
  border-radius: 20px;
  background-color: #eaeaea;
  color: #212121;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  text-align: center;
}

.title-chip-xs {
  width: fit-content;
  min-width: 30px;
  padding: 6px 8px!important;
  text-align: center;
  border-radius: 20px;
  background-color: #14b2ae;
  color: #ffffff;
  font-size: 11px!important;
  line-height: 11px!important;
  font-weight: 600;
}
.title-chip-xs-auto {
  width: fit-content;
  min-width: 30px;
  padding: 6px 8px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: $accent;
  color: #212121;
  font-size: 11px;
  line-height: 11px;
  font-weight: 600;
}
.title-chip {
  width: fit-content;
  padding: 8px 40px 8px 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #ffffff;
  color: #444444;
  font-size: 13px!important;
  line-height: 13px;
  font-weight: 600!important;
  //box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.09) !important;
}
.title-chip-round {
  width: fit-content;
  padding: 6px 10px 6px 20px;
  border-radius: 50px;
  background-color: #eaeaea;
  color: #212121;
  font-size: 13px;
  line-height: 13px;
  font-weight: 600;
}

.expert-type {
  padding: 6px 20px;
  border-radius: 15px;
  font-size: 13px;
  font-weight: 600;
  background: #14b2ae;
  color: white;
  line-height: 13px;
}

.border-inherit {
  border: inherit !important;
}

.circle-step-warn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: #F46A70;
  text-align: center !important;
  line-height: 60px;
  font-size: 13px;
  padding: 5px !important;
  text-transform: uppercase;
  font-weight: 600;
  vertical-align: middle !important;
  border : 1px solid #F46A70;
}
.circle-step-black {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: #212121;
  text-align: center !important;
  line-height: 60px;
  font-size: 13px;
  padding: 5px !important;
  text-transform: uppercase;
  font-weight: 600;
  vertical-align: middle !important;
  border : 1px solid #212121;
}
.circle-step-bg {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: #212121;
  text-align: center !important;
  line-height: 60px;
  font-size: 13px;
  padding: 5px !important;
  text-transform: uppercase;
  font-weight: 600;
  vertical-align: middle !important;
  background: #eaeaea;
}
.circle-step-black-dot {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: #212121;
  text-align: center !important;
  line-height: 60px;
  font-size: 13px;
  padding: 5px !important;
  text-transform: uppercase;
  font-weight: 600;
  vertical-align: middle !important;
  border : 1px dotted #212121;
}

.icon-circle-wrapper-xs {
  width: 70px;
  border-radius: 20px;
  line-height: 12px;
  padding: 6px 10px;
  background-color: #eaeaea;
  color: #212121;
  font-size: 12px;
  vertical-align: middle;
  font-weight: 600;
  text-align: center;
}
.icon-circle-wrapper-xs-primary {
  width: 66px;
  padding: 6px 2px;
  border-radius: 20px;
  line-height: 12px;
  border: 1px solid #14b2ae;
  color: #14b2ae;
  font-size: 12px;
  vertical-align: middle;
  font-weight: 600;
  text-align: center;
}

.mat-badge-accent .mat-badge-content {
  background: #14b2ae!important;
  color: #ffffff!important;
  font-size: 11px!important;
  text-align: center!important;
}

.img-help {
  padding: 6px 10px;
  border-radius: 4px;
  background-color: $gray-dark;
  color: white;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  text-align: center;
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #14b2ae!important;
  --mdc-protected-button-label-text-color: #fff;
  --mat-protected-button-state-layer-color: #fff;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #14b2ae!important;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #14b2ae!important;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}
