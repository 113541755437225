$i: 10;
@while ($i <= 200) {
  .mat-icon-size-#{$i} {
    width: #{$i}px !important;
    height: #{$i}px !important;
    line-height: #{$i}px;
    font-size: #{$i}px;
  }
  $i: $i + 1;
}

.mat-icon-subheading {
  font-size: 20px !important;
  line-height: 24px !important;
}
