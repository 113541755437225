@import './variables';

@font-face {
  font-family: 'yg-jalnan';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_four@1.2/JalnanOTF00.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'S-CoreDream-9Black';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-9Black.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'S-CoreDream-8Heavy';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-8Heavy.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'S-CoreDream-7ExtraBold';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-7ExtraBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'S-CoreDream-6Bold';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-6Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'S-CoreDream-5Medium';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-5Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'S-CoreDream-4Regular';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-4Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'S-CoreDream-3Light';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-3Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'S-CoreDream-2ExtraLight';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-2ExtraLight.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'S-CoreDream-1Thin';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-1Thin.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.ff-sc-9 {
  font-family: 'S-CoreDream-9Black', serif !important;
}

.ff-sc-8 {
  font-family: 'S-CoreDream-8Heavy', serif !important;
}

.ff-sc-7 {
  font-family: 'S-CoreDream-7ExtraBold', serif !important;
}

.ff-sc-6 {
  font-family: 'S-CoreDream-6Bold', serif !important;
}

.ff-sc-5 {
  font-family: 'S-CoreDream-5Medium', serif !important;
}

.ff-sc-4 {
  font-family: 'S-CoreDream-4Regular', serif !important;
}

.ff-sc-3 {
  font-family: 'S-CoreDream-3Light', serif !important;
}

.ff-sc-2 {
  font-family: 'S-CoreDream-2ExtraLight', serif !important;
}

.ff-sc-1 {
  font-family: 'S-CoreDream-1Thin', serif !important;
}

.ff-jalnan {
  font-family: 'yg-jalnan', serif !important;
  letter-spacing: 1px !important;
}

a {
  color: inherit;
  text-decoration: none;
}

.number {
  letter-spacing: 1px;
  font-size: 14px;
}

.text-pre {
  white-space: pre-line;
  line-height: 1.7;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right !important;
}

.text-middle {
  vertical-align: middle !important;
}

.text-bold {
  font-weight: 700 !important;
}

.text-link {
  text-decoration: underline;
  color: black;
  cursor: pointer;
  font-weight: 600;
}

.number-circle {
  width: 22px;
  height: 22px;
  line-height: 22px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  background: #dadada;
  color: #555555;
  text-align: center;
  font-size: 12px;
}

.number-circle-subhead {
  width: 22px;
  height: 22px;
  line-height: 22px;
  border-radius: 50%;
  display: inline-block;
  background: $primary-light;
  color: white;
  text-align: center;
  font-size: 12px;
  margin: 0 7px 10px 0;
}

.text-circle {
  display: inline-block;
  min-width: 50px;
  min-height: 50px;
  line-height: 50px !important;
  border-radius: 50%;
  text-align: center;
}

.text-circle-mini {
  display: inline-block;
  min-width: 50px;
  min-height: 50px;
  line-height: 50px !important;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
}

.text-circle-40 {
  display: inline-block;
  min-width: 40px;
  min-height: 40px;
  line-height: 40px !important;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
}

.text-circle-60 {
  display: inline-block;
  min-width: 60px;
  min-height: 60px;
  line-height: 60px !important;
  border-radius: 50%;
  text-align: center;
  font-weight: 600;
}

.text-circle-70 {
  display: inline-block;
  min-width: 70px;
  min-height: 70px;
  line-height: 70px !important;
  border-radius: 50%;
  text-align: center;
  font-weight: 600;
}

.text-badge {
  width: fit-content;
  border: 1px solid $gray-light;
  border-radius: 15px;
  padding: 3px 8px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}

.text-badge-gray {
  width: fit-content;
  border: 1px solid $gray;
  border-radius: 15px;
  padding: 3px 8px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
}

.text-badge-warn-s {
  width: fit-content;
  border: 1px solid $warn;
  border-radius: 20px;
  padding: 3px 10px !important;
  font-size: 11px !important;
  font-weight: 600;
  line-height: 11px !important;
  color: $warn;
}

.text-badge-primary-s {
  width: fit-content;
  border: 1px solid $primary-darker;
  border-radius: 20px;
  padding: 3px 10px !important;
  font-size: 11px;
  font-weight: 600;
  line-height: 11px !important;
  color: $primary-darker;
}

.text-badge-primary {
  width: fit-content;
  border: 1px solid $primary-darker;
  color: $primary;
  border-radius: 15px;
  padding: 3px 8px !important;
  font-size: 12px;
  line-height: 12px !important;
  font-weight: 500;
}

.text-badge-white {
  width: fit-content;
  border: 1px solid #ffffff;
  color: #ffffff;
  border-radius: 15px;
  padding: 3px 8px !important;
  font-size: 12px;
  line-height: 12px !important;
  font-weight: 500;
}

.badge {
  background-color: $primary-darker;
  border-radius: 15px;
  padding: 3px 8px;
  font-size: 11px !important;
  line-height: 11px !important;
  font-weight: 500;
  color: #ffffff;
}

.badge-title {
  background-color: $primary-darker;
  color: #ffffff;
  border-radius: 4px;
  padding: 3px 8px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}

.badge-kr {
  margin-right: 5px;
  padding: 3px 10px 5px!important;
  border-radius: 20px;
  font-size: 11px !important;
  line-height: 13px !important;
  font-weight: 500;
  text-align: center;
}

.badge-kr-md {
  min-width: 30px;
  padding: 6px 10px;
  border-radius: 20px;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
}

.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px !important;
}

.mat-badge-small .mat-badge-content {
  width: 21px !important;
  height: 21px !important;
  line-height: 20px !important;
  font-size: 11px !important;
  border: 1px solid #ffffff !important;
}

.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -1px !important;
}

.lh-22 {
  line-height: 22px !important;
}

.lh-1-7 {
  line-height: 1.7 !important;
}

.lh-1-8 {
  line-height: 1.8 !important;
}

.lh-0 {
  line-height: 0 !important;
}

.f-11 {
  font-size: 11px !important;
}

.f-12 {
  font-size: 12px !important;
}

.f-13 {
  font-size: 13px !important;
}

.f-14 {
  font-size: 14px !important;
}

.f-15 {
  font-size: 15px !important;
}

.f-16 {
  font-size: 16px !important;
}

.f-18 {
  font-size: 18px !important;
}

.f-20 {
  font-size: 20px !important;
}

.f-22 {
  font-size: 22px !important;
}

.f-25 {
  font-size: 25px !important;
}

.f-30 {
  font-size: 30px !important;
}

.f-35 {
  font-size: 35px !important;
}

.f-40 {
  font-size: 40px !important;
}

.f-50 {
  font-size: 50px !important;
}

.f-60 {
  font-size: 60px;
}

.f-70 {
  font-size: 70px !important;
}

.f-80 {
  font-size: 80px !important;
}

.f-90 {
  font-size: 90px;
}

.f-100 {
  font-size: 100px;
}

.f-110 {
  font-size: 110px;
}

.f-120 {
  font-size: 120px;
}

.f-130 {
  font-size: 130px;
}

.f-400 {
  font-weight: 400 !important;
}

.f-500 {
  font-weight: 500 !important;
}

.f-600 {
  font-weight: 600 !important;
}

.f-700 {
  font-weight: 700 !important;
}

.f-800{
  font-weight: 800!important;
}

.mat-headline {
  font: 800 24px/24px Noto Sans KR !important;
  margin: 20px 0 16px !important;
  line-height: 1.5!important;
}
.mat-headline-2 {
  font: 700 35px/35px Noto Sans KR !important;
  margin: 10px 0 20px 0 !important;
  line-height: 1.5!important;
}

.mat-headline-3 {
  font: 500 35px/35px Noto Sans KR !important;
  margin: 0 0 30px 0 !important;
  line-height: 1.5!important;
}


.mat-title {
  font: 600 20px/20px Noto Sans KR !important;
  margin: 0 0 !important;
  line-height: 1.5!important;
}

.mat-subheading-2 {
  font: 400 18px/18px Noto Sans KR;
  margin-bottom: 20px!important;
  line-height: 1.8!important;

}

.mat-subheading-1 {
  font: 500 15px/15px Noto Sans KR;
  margin-bottom: 20px!important;
  line-height: 1.5!important;
}

.mat-body{
  font: 500 13px/13px Noto Sans KR!important;
}

.mat-body-1{
  font-size: 13px!important;
  font-weight: 400;
  line-height: 1.5!important;
}

.mat-body-2{
  font: 600 13px / 22px Noto Sans KR !important;
}

.mat-body-3 {
  font: 500 16px/18px Noto Sans KR !important;
  line-height: 1.8 !important;
  color: #505050;
}

.mat-caption{
  font: 500 12px/22px Noto Sans KR!important;
}

.mat-caption-2{
  font: 400 13px/13px Noto Sans KR!important;
  color: #999999;
  margin-bottom: 20px;
}

.mat-hint {
  font-size: 1.2em !important;
  line-height: 1.8 !important;
  color: $gray-deep !important;
  font-weight: 500;
}

.mat-error {
  font-size: 12px;
  line-height: 1.6;
  padding-top: 10px !important;
  color: $warn;
}

.mat-expansion-panel-content {
  font: 500 13px/22px Noto Sans KR !important;
  padding-bottom: 0px !important;
  line-height: 1.8 !important;
}

.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  padding: 0 5px !important;
}

.mat-dialog-title {
  font: 600 17px/24px Noto Sans KR !important;
}

.mat-drawer-container {
  color: #212121 !important;
}

.m-t-m10 {
  margin-top: -10px !important;
}

.m-t-m12 {
  margin-top: -12px !important;
}

.m-t-m20 {
  margin-top: -20px !important;
}

.m-t-m30 {
  margin-top: -30px !important;
}

.m-t-m40 {
  margin-top: -40px !important;
}

.m-t-m60 {
  margin-top: -60px !important;
}

.m-l-m10 {
  margin-left: -10px !important;
}

.m-l-m15 {
  margin-left: -15px !important;
}

.m-l-m20 {
  margin-left: -20px !important;
}

.text-a-left {
  text-align: left !important;
}

.text-pointline {
  position: absolute;
  width: calc(100% + 20px);
  height: 12px;
  top: 11px;
  margin-left: -10px;
  background-color: rgba(255, 204, 26, 0.6);
}


.underline {
  line-height: 1.2;
  background-image: linear-gradient(transparent calc(100% - 3px), #212121 3px);
  background-repeat: no-repeat;
  transition: background-size 0.8s;
  color: #212121;
}

.underline-slim {
  line-height: 1.2;
  background-image: linear-gradient(transparent calc(100% - 3px), #212121 3px);
  background-repeat: no-repeat;
  transition: background-size 0.8s;
  color: inherit;
}

.underline {
  background-size: 100% 90%;
}

.underline-slim {
  background-size: 100% 110%;
}

.underline.primary {
  background-image: linear-gradient(transparent 57%, $primary-light 60%);
}

.underline.accent {
  background-image: linear-gradient(transparent 57%, $accent 50%);
}

.underline.gray {
  background-image: linear-gradient(transparent 57%, #dadada 50%);
}

.underline.white {
  background-image: linear-gradient(transparent 57%, #ffffff 50%);
}

.underline-slim.primary {
  background-image: linear-gradient(transparent 60%, $primary-light 50%);
}

.underline-slim.accent {
  background-image: linear-gradient(transparent 60%, $accent 50%);
}

.underline-slim.gray {
  background-image: linear-gradient(transparent 60%, #dadada 40%);
}

.underline-slim.white {
  background-image: linear-gradient(transparent 60%, #ffffff 40%);
}

.f-8 {
  font-size: 8px !important;
}

.f-9 {
  font-size: 0.5em !important;
}

.f-10 {
  font-size: 10px !important;
}

.blink-info {
  animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
