table {
  width: 100%;
}

@media screen and (max-width: 599px) {
  td.mat-cell:first-of-type, td.mat-footer-cell:first-of-type, th.mat-header-cell:first-of-type {
    padding-left: 2px !important;
  }

  td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type, th.mat-header-cell:last-of-type {
    padding-right: 2px !important;
  }
}
