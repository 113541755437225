@import '../_variables.scss';

.suffix-text {
  .mat-form-field-suffix {
    top: 0 !important;
  }
}

input.mat-input-element:disabled {
  color: #212121 !important;
  cursor: not-allowed !important;
}

.no-hint {
  margin-bottom: 5px !important;

  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }
}

.mat-checkbox {
  margin-left: 2px;
}

.mat-radio-button {
  margin-left: 2px;
}
.mat-radio-label {
  font-weight: 600!important;
}

textarea.mat-input-element {
  padding: 2px 0;
  margin: -2px 0;
  line-height: 1.8;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.8) !important;
}

.mat-form-field {
  font-size: 14px !important;
}

.search-bar .mat-input-element {
  padding: 3px 0 !important;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element, .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: $gray-dark!important;
}

.mat-radio-button.mat-primary .mat-radio-inner-circle, .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: $gray-dark!important;
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: $gray-dark!important;
}

.mat-primary .mat-slider-track-fill, .mat-primary .mat-slider-thumb, .mat-primary .mat-slider-thumb-label {
  background-color: $gray-dark!important;
}
.mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: $gray-dark!important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: $gray-dark!important;
  font-weight: 600!important;
}
//.mat-form-field-infix {
//  display: block;
//  position: relative;
//  flex: auto;
//  min-width: 0;
//  width: 180px;
//  text-align: center!important;
//}
.mat-form-field-ripple {
  background-color: rgb(27 164 158 / 87%)!important;
}

.mat-input-element {
  caret-color: $gray-dark!important;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color : #444444!important;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: $primary-darker!important;
}
.mat-slide-toggle-label {
  //font-size: 14px!important;
  font-weight: 600!important;
}

.mat-dialog-container .mat-checkbox-label {
  font-size: 14px!important;
  font-weight: 600!important;
}
.mat-checkbox-label {
  //font-size: 14px!important;
  font-weight: 600!important;
}


.form-question {
  display: none;
}
::ng-deep .form-question .mat-form-field-appearance-outline .mat-form-field-flex {
  padding: 0 .75em 0 .1em!important;
}

.form-width-120 {
  width: 120px!important;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: .6em 0 1.2em 0!important;
}


.mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-state-layer-color: #14b2ae;
  --mdc-switch-selected-handle-color: #14b2ae;
  --mdc-switch-selected-hover-state-layer-color: #14b2ae;
  --mdc-switch-selected-pressed-state-layer-color: #14b2ae;
  --mdc-switch-selected-focus-handle-color: #14b2ae;
  --mdc-switch-selected-hover-handle-color: #14b2ae;
  --mdc-switch-selected-pressed-handle-color: #14b2ae;
  --mdc-switch-selected-focus-track-color: #14b2ae;
  --mdc-switch-selected-hover-track-color: #14b2ae;
  --mdc-switch-selected-pressed-track-color: #14b2ae;
  --mdc-switch-selected-track-color: #14b2ae;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
}

.mdc-checkbox {
  display: inline-block;
  position: relative;
  flex: 0 0 18px;
  box-sizing: content-box;
  width: 18px;
  height: 18px;
  line-height: 0;
  white-space: nowrap;
  cursor: pointer;
  vertical-align: bottom;
  margin-left: -8px!important;
}
.mdc-radio {
  display: inline-block;
  position: relative;
  flex: 0 0 auto;
  box-sizing: content-box;
  width: 20px;
  height: 20px;
  cursor: pointer;
  will-change: opacity,transform,border-color,color;
  margin-left: -8px!important;
}
