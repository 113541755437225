@import "./variables";

@media print {
  body {
    -webkit-print-color-adjust: exact;
    font-size: 10px;
  }

  .noprint,
  div.alert,
  header,
  button,
  form,
  app-toolbar,
  app-footer,
  app-bottom-toolbar {
    display: none !important;
  }

  @page {
    margin: 0;
  }
}
