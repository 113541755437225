.img-circle-25 {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
.img-circle-30 {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.img-circle-40 {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.img-circle-50 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.img-circle-60 {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.img-circle-80 {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.img-circle-80 {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}
.img-circle-100 {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.img-circle-120 {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}
.img-circle-150 {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.img-cube-40 {
  width: 40px;
  height: 40px;
  border-radius: 5%;
}
.img-cube-60 {
  width: 60px;
  height: 60px;
  border-radius: 5%;
  background-color: #fafafa;
}
.img-cube-80 {
  width: 80px;
  height: 80px;
  border-radius: 5%;
}
.img-size-14 {
  height: 14px;
  width: auto;
}
.img-size-15 {
  height: 15px;
  width: auto;
}
.img-size-16 {
  height: 16px;
  width: auto;
}
.img-size-30 {
  height: 30px;
  width: auto;
}
.img-size-35 {
  height: 35px;
  width: auto;
}
.img-size-38 {
  height: 38px;
  width: auto;
}
.img-size-40 {
  height: 40px;
  width: auto;
}
.img-size-45 {
  height: 45px;
  width: auto;
}
.img-size-50 {
  height: 50px;
  width: auto;
}
.img-size-55 {
  height: 55px;
  width: auto;
}
.img-size-60 {
  height: 60px;
  width: auto;
}
.img-size-65 {
  height: 65px;
  width: auto;
}
.img-size-70 {
  height: 70px;
  width: auto;
}
.img-size-80 {
  height: 80px;
  width: auto;
}
.img-size-85 {
  height: 85px;
  width: auto;
}
.img-size-90 {
  height: 90px;
  width: auto;
}
.img-size-95 {
  height: 95px;
  width: auto;
}
.img-size-100 {
  height: 100px;
  width: auto;
}
.img-size-103 {
  height: 103px;
  width: auto;
}
.img-size-105 {
  height: 105px;
  width: auto;
}
.img-size-108 {
  height: 108px;
  width: auto;
}
.img-size-110 {
  height: 110px;
  width: auto;
}
.img-size-115 {
  height: 115px;
  width: auto;
}
.img-size-117 {
  height: 117px;
  width: auto;
}
.img-size-120 {
  height: 120px;
  width: auto;
}
.img-size-130 {
  height: 130px;
  width: auto;
}
.img-size-133 {
  height: 133px;
  width: auto;
}
.img-size-134 {
  height: 134px;
  width: auto;
}
.img-size-135 {
  height: 135px;
  width: auto;
}
.img-size-138 {
  height: 138px;
  width: auto;
}
.img-size-140 {
  height: 140px;
  width: auto;
}
.img-size-150 {
  height: 150px;
  width: auto;
}
.img-size-155 {
  height: 155px;
  width: auto;
}
.img-size-160 {
  height: 160px;
  width: auto;
}
.img-size-165 {
  height: 165px;
  width: auto;
}
.img-size-170 {
  height: 170px;
  width: auto;
}
.img-size-171 {
  height: 171px;
  width: auto;
}
.img-size-172 {
  height: 172px;
  width: auto;
}
.img-size-173 {
  height: 173px;
  width: auto;
}
.img-size-180 {
  height: 180px;
  width: auto;
}
.img-size-185 {
  height: 185px;
  width: auto;
}
.img-size-190 {
  height: 190px;
  width: auto;
}
.img-size-200 {
  height: 200px;
  width: auto;
}
.img-size-205 {
  height: 205px;
  width: auto;
}
.img-size-207 {
  height: 207px;
  width: auto;
}
.img-size-210 {
  height: 210px;
  width: auto;
}
.img-size-212 {
  height: 212px;
  width: auto;
}
.img-size-220 {
  height: 220px;
  width: auto;
}
.img-size-223 {
  height: 223px;
  width: auto;
}
.img-size-225 {
  height: 225px;
  width: auto;
}
.img-size-230 {
  height: 230px;
  width: auto;
}
.img-size-232 {
  height: 232px;
  width: auto;
}
.img-size-240 {
  height: 240px;
  width: auto;
}
.img-size-242 {
  height: 242px;
  width: auto;
}
.img-size-245 {
  height: 245px;
  width: auto;
}
.img-size-248 {
  height: 248px;
  width: auto;
}
.img-size-250 {
  height: 250px;
  width: auto;
}
.img-size-260 {
  height: 260px;
  width: auto;
}
.img-size-265 {
  height: 265px;
  width: auto;
}
.img-size-267 {
  height: 267px;
  width: auto;
}
.img-size-270 {
  height: 270px;
  width: auto;
}
.img-size-273 {
  height: 273px;
  width: auto;
}
.img-size-275 {
  height: 275px;
  width: auto;
}
.img-size-280 {
  height: 280px;
  width: auto;
}
.img-size-290 {
  height: 290px;
  width: auto;
}
.img-size-295 {
  height: 295px;
  width: auto;
}
.img-size-300 {
  height: 300px;
  width: auto;
}
.img-size-302 {
  height: 302px;
  width: auto;
}
.img-size-305 {
  height: 305px;
  width: auto;
}
.img-size-310 {
  height: 310px;
  width: auto;
}
.img-size-315 {
  height: 315px;
  width: auto;
}
.img-size-320 {
  height: 320px;
  width: auto;
}
.img-size-321 {
  height: 321px;
  width: auto;
}
.img-size-323 {
  height: 323px;
  width: auto;
}
.img-size-325 {
  height: 325px;
  width: auto;
}
.img-size-330 {
  height: 330px;
  width: auto;
}
.img-size-332 {
  height: 332px;
  width: auto;
}
.img-size-335 {
  height: 335px;
  width: auto;
}
.img-size-340 {
  height: 340px;
  width: auto;
}
.img-size-345 {
  height: 345px;
  width: auto;
}
.img-size-350 {
  height: 350px;
  width: auto;
}
.img-size-353 {
  height: 353px;
  width: auto;
}
.img-size-360 {
  height: 360px;
  width: auto;
}
.img-size-365 {
  height: 365px;
  width: auto;
}
.img-size-367 {
  height: 367px;
  width: auto;
}
.img-size-370 {
  height: 370px;
  width: auto;
}
.img-size-373 {
  height: 373px;
  width: auto;
}
.img-size-375 {
  height: 375px;
  width: auto;
}
.img-size-380 {
  height: 380px;
  width: auto;
}
.img-size-385 {
  height: 385px;
  width: auto;
}
.img-size-390 {
  height: 390px;
  width: auto;
}
.img-size-392 {
  height: 392px;
  width: auto;
}
.img-size-393 {
  height: 393px;
  width: auto;
}
.img-size-395 {
  height: 395px;
  width: auto;
}
.img-size-400 {
  height: 400px;
  width: auto;
}
.img-size-405 {
  height: 405px;
  width: auto;
}
.img-size-410 {
  height: 410px;
  width: auto;
}
.img-size-417 {
  height: 417px;
  width: auto;
}
.img-size-420 {
  height: 420px;
  width: auto;
}
.img-size-425 {
  height: 425px;
  width: auto;
}
.img-size-430 {
  height: 430px;
  width: auto;
}
.img-size-432 {
  height: 432px;
  width: auto;
}
.img-size-435 {
  height: 435px;
  width: auto;
}
.img-size-440 {
  height: 440px;
  width: auto;
}
.img-size-445 {
  height: 445px;
  width: auto;
}
.img-size-450 {
  height: 450px;
  width: auto;
}
.img-size-460 {
  height: 460px;
  width: auto;
}
.img-size-465 {
  height: 465px;
  width: auto;
}
.img-size-470 {
  height: 470px;
  width: auto;
}
.img-size-475 {
  height: 475px;
  width: auto;
}
.img-size-478 {
  height: 478px;
  width: auto;
}
.img-size-480 {
  height: 480px;
  width: auto;
}
.img-size-486 {
  height: 486px;
  width: auto;
}
.img-size-495 {
  height: 495px;
  width: auto;
}
.img-size-500 {
  height: 500px;
  width: auto;
}
.img-size-505 {
  height: 505px;
  width: auto;
}
.img-size-508 {
  height: 508px;
  width: auto;
}
.img-size-510 {
  height: 510px;
  width: auto;
}
.img-size-520 {
  height: 520px;
  width: auto;
}
.img-size-530 {
  height: 530px;
  width: auto;
}
.img-size-533 {
  height: 533px;
  width: auto;
}
.img-size-540 {
  height: 540px;
  width: auto;
}
.img-size-543 {
  height: 543px;
  width: auto;
}
.img-size-545 {
  height: 545px;
  width: auto;
}
.img-size-548 {
  height: 548px;
  width: auto;
}
.img-size-565 {
  height: 565px;
  width: auto;
}
.img-size-570 {
  height: 570px;
  width: auto;
}
.img-size-571 {
  height: 571px;
  width: auto;
}
.img-size-575 {
  height: 575px;
  width: auto;
}
.img-size-580 {
  height: 580px;
  width: auto;
}
.img-size-587 {
  height: 587px;
  width: auto;
}
.img-size-590 {
  height: 590px;
  width: auto;
}
.img-size-605 {
  height: 605px;
  width: auto;
}
.img-size-610 {
  height: 610px;
  width: auto;
}
.img-size-615 {
  height: 615px;
  width: auto;
}
.img-size-625 {
  height: 625px;
  width: auto;
}
.img-size-631 {
  height: 631px;
  width: auto;
}
.img-size-640 {
  height: 640px;
  width: auto;
}
.img-size-660 {
  height: 660px;
  width: auto;
}
.img-size-662 {
  height: 662px;
  width: auto;
}
.img-size-690 {
  height: 690px;
  width: auto;
}
.img-size-735 {
  height: 735px;
  width: auto;
}
.img-size-800 {
  height: 800px;
  width: auto;
}
.img-size-890 {
  height: 890px;
  width: auto;
}
.img-size-120w {
  height: auto;
  width: 120px;
}
.img-size-125w {
  height: auto;
  width: 125px;
}
.img-size-130w {
  height: auto;
  width: 130px;
}
.img-size-140w {
  height: auto;
  width: 140px;
}
.img-size-150w {
  height: auto;
  width: 150px;
}

.img-size-40p {
  height: auto;
  width: 40%;
}
.img-size-50p {
  height: auto;
  width: 50%;
}
.img-size-60p {
  height: auto;
  width: 60%;
}
.img-size-70p {
  height: auto;
  width: 70%;
}
.img-size-80p {
  height: auto;
  width: 80%;
}
.img-size-90p {
  height: auto;
  width: 90%;
}
.img-size-98p {
  height: auto;
  width: 98%;
}
.img-size-100p {
  height: auto;
  width: 100%;
}
