@import './variables';

.border-e2 {
  border: 1px solid #e2e2e2;
  border-radius: 4px;
}

.border-bd {
  border: 1px solid #BDBDBD;
  border-radius: 4px;
}

.border-9e {
  border: 1px solid #9e9e9e;
  border-radius: 4px;
}

.border-primary {
  border: 1px solid $primary;
  border-radius: 4px;
}

.border-warn {
  border: 1px solid $warn;
  border-radius: 4px;
}

.border-h-ea {
  border-left: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
}

.border-h-r {
  border-right: 1px solid #eaeaea;
}

.border-h-r-black {
  border-right: 1px dotted #555555;
}

.border-t {
  border-top: 1px solid;
}

.border-v {
  border-top: 1px solid;
  border-bottom: 1px solid;
}

.border-v-primary {
  border-top: 1px solid $primary;
  border-bottom: 1px solid $primary;
}

.border-b-primary {
  border-bottom: 1px solid $primary;
}

.border-title {
  border-bottom: 1px solid $gray;
  padding-bottom: 10px;
}

.border-dashed-7 {
  border: 1px dashed #777777;
  border-radius: 4px;
}

.border-dashed-9 {
  border: 1px dashed #999999;
  border-radius: 4px;
}

.border-b-dashed {
  border-bottom: 1px dashed #777777;
  padding-bottom: 5px;
}

.border-b-dashed-gray {
  border-bottom: 1px dashed #999999;
  padding-bottom: 5px;
}

.border-b-e2 {
  border-bottom: 1px solid #e2e2e2;
}

.border-t-dashed {
  border-top: 1px dashed #777777;
}

.border-shadow {
  background: white;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, .28);
  box-sizing: border-box;
  opacity: 1;
}

.border-left-dotted {
  border-left: 1px dotted #212121;
}

.border-left-solid {
  border-left: 1px solid #212121;
}

.border-none {
  border: none !important;
}
