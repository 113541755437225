@import './variables';

.bg-smoke {
  background: whitesmoke !important;
}

.bg-white {
  background: white !important;
}

.bg-gray-fa {
  background-color: #fafafa !important;
}

.bg-gray-ea {
  background-color: #eaeaea !important;
}

.bg-gray-da {
  background-color: #dadada !important;
}
.bg-gray-ca {
  background-color: #cacaca !important;
}
.bg-gray-ba {
  background-color: #bababa !important;
}

.bg-gray-light {
  background-color: $gray-light !important;
}

.bg-gray {
  color: white !important;
  background-color: $gray !important;
}

.bg-gray-deep {
  color: white !important;
  background-color: $gray-deep !important;
}

.bg-gray-dark {
  color: white !important;
  background-color: $gray-dark !important;
}

.bg-primary-sky {
  background-color: rgba(51,204,204,.7);
}

.bg-primary-light {
  background: $primary-light !important;
}

.bg-primary-darker {
  background: $primary-darker !important;
  color: white !important;
}

.bg-primary {
  background: $primary !important;
  color: white !important;
}

.bg-accent {
  background: $accent !important;
  color: #212121;
}

.bg-accent-light {
  background: $accent-light !important;
  color: #212121 !important;
}
.c-accent-light {
  color: $accent-light;
}
.c-accent {
  color: $accent;
}

.bg-accent-darker {
  background: #E8C248 !important;
  color: #212121;
}

.c-accent-darker {
  color: #E8C248;
}

.bg-primary-sky {
  background-color: #e6efff;
}

.c-primary-light {
  color: $primary-light !important;
}

.c-primary {
  color: $primary !important;
}

.c-primary-darker {
  color: $primary-darker !important;
}


.c-gray-light {
  color: $gray-light !important;
}

.c-gray {
  color: $gray !important;
}

.c-gray-deep {
  color: $gray-deep !important;
}

.c-gray-dark {
  color: $gray-dark !important;
}

.c-gray-ca {
  color: #cacaca !important;
}

.c-gray-da {
  color: #dadada !important;
}

.c-gray-ea {
  color: #eaeaea !important;
}


.c-white {
  color: white !important;
}

.c-warn-light {
  color: $warn-light !important;
}

.bg-warn-light {
  background-color: $warn-light !important;
}

.c-warn {
  color: $warn !important;
}

.bg-warn {
  background-color: $warn !important;
}
.c-pink {
  color: #DB5291;
}
.bg-pink {
  background-color: #DB5291;
}
.c-mint {
  color: #25ccbe!important;
}
.bg-mint {
  background-color: #25ccbe!important;
  color: white!important;
}
.c-mint-dark {
  color: #14b2ae!important;
}
.bg-mint-dark {
  background-color: #14b2ae!important;
  color: white!important;
}

.c-green-light {
  color: #43A950;
}
.c-green {
  color: #3F984B;
}
.c-green-dark {
  color: #117430;
}
.bg-green-light {
  background-color: #43A950;
}
.bg-green {
  background-color: #3F984B;
}
.bg-green-dark {
  background-color: #117430;
}

.c-black {
  color: #212121 !important;
}

.c-dark {
  color: #000000;
}

.bg-bg {
  background-color: #212121;
}

.c-bg {
  color: #bdc1c6;
}

.mat-mini-fab.mat-primary {
  background-color: $primary !important;
}

.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: $primary !important;
}

.mat-calendar-body-selected {
  background-color: $primary !important;
  color: white!important;
}
