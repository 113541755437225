@import '../variables';

//.mat-tab-label-active {
//  background: $primary-darker;
//  border-top-left-radius: 10px !important;
//  border-top-right-radius: 10px !important;
//  .mat-tab-label-content {
//    color: #ffffff;
//    font-weight: 700 !important;}
//}


//.mat-tab-label-active {
//  background: inherit;
//  border-top-left-radius: 10px !important;
//  border-top-right-radius: 10px !important;
//  border-bottom: 2px solid #212121!important;
//  .mat-tab-label-content {
//    color: #212121;
//    font-weight: 700 !important;}
//}

.mat-tab-label-active {
  background: inherit;
  border-top-left-radius: 10px!important;
  border-top-right-radius: 10px!important;
  border-bottom: 2px solid #212121!important;
}


.mat-tab-label, .mat-tab-link {
  //color: rgba(0, 0, 0, 0.7);
  color: rgba(0,0,0,.95);
  z-index: 999!important;
  font-size: 14px!important;
}

.mat-ink-bar {
  visibility: hidden !important;
}

.mat-tab-label {
  height: 35px !important;
  padding: 0 5px!important;
  cursor: pointer;
  box-sizing: border-box;
  opacity: 1 !important;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  position: relative;
}
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-top: -5px;
}

@media (max-width: 599px) {
  .mat-tab-label {
    min-width: 90px!important;
  }
}

.mat-tab-label .mat-tab-label-content {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  font-weight: 600;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.95);
  z-index: 999 !important;
  font-size: 14px !important;
}

@media screen and (max-width: 959px) {
  .mat-tab-label, .mat-tab-link {
    font-size: 13px !important;
  }
}


.mat-step-icon {
  border-radius: 50%;
  height: 15px !important;
  width: 15px !important;
  flex-shrink: 0;
  position: relative;
  //background-color: #33cccc !important;
}

.mat-tab-body {
  padding: 8px 0;
}

.mat-tab-header-pagination-before, .mat-tab-header-rtl .mat-tab-header-pagination-after {
  padding-left: 0px !important;
}

.mat-tab-header-pagination-after, .mat-tab-header-rtl .mat-tab-header-pagination-before {
  padding-right: 0px !important;
}

.mat-tab-header-pagination {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 2;
  -webkit-tap-highlight-color: transparent;
  touch-action: none;
}

.mat-horizontal-stepper-header {
  display: flex;
  height: 72px;
  overflow: hidden;
  align-items: center;
  padding: 0 10px!important;
}

::ng-deep .mat-step-header .mat-step-icon {
  //background-color: #cccccc;
  background-color: #33cccc !important;
  color: white;
  line-height: 16px;
}

.mat-step-icon-content {
  display: none!important;
}

.mat-horizontal-stepper-header {
  display: flex;
  height: 50px !important;
  overflow: hidden;
  align-items: center;
  padding: 0 24px;
}
.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
  background-color: $gray-dark!important;
  color: white;
}

.mat-step-text-label {
  font-weight: 600!important;
}

.mat-step-header .mat-step-icon {
  background-color: #dadada!important;
  color: white;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Noto Sans KR;
  --mat-tab-header-label-text-size: 14px!important;
  --mat-tab-header-label-text-tracking: normal;
  --mat-tab-header-label-text-line-height: 14px;
  --mat-tab-header-label-text-weight: 600;
}

//.mdc-tab{
//  min-width: 90px;
//  max-width: 160px;
//}


.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #212121;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #000000;
  --mat-tab-header-active-ripple-color: #212121;
  --mat-tab-header-inactive-ripple-color: #212121;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #212121;
  --mat-tab-header-active-hover-label-text-color: #212121;
  --mat-tab-header-active-focus-indicator-color: #212121;
  --mat-tab-header-active-hover-indicator-color: #212121;
}
