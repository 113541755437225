.ql-editor img {
  width: 100%;
  position: relative;
}

.ql-editor {
  padding: 20px 0px!important;
  line-height: 1.7!important;
}

.mat-card-content {
  font-size: 14px!important;
}

p {
  margin: 4px 0px!important;
}
